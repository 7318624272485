import * as React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Layout, Hero, Card, ArticlePreview, Formik, Button } from '@base';

const Section = styled.section`
  display: flex;
  justify-content: center;
  max-width: 744px;
  margin: 0 auto;
  flex-wrap: wrap;
`;

const Headline = styled.h2`
  font-size: 6rem;
  text-align: center;
  font-family: poppins;
  line-height: 8rem;
  margin: 0;
  font-weight: bold;
`;

const Teaser = styled.p`
  font-size: 2.2rem;
  text-align: center;
  line-height: 1.5em;
  font-weight: 300;
`;

const ContentBoxContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 4rem;
`;

const mockContent = [
  {
    imageUrl:
      'https://d33wubrfki0l68.cloudfront.net/7984e4ddb4991139e57471b2676586474545ec5f/07cde/images/features/teams.png',
    title: 'Designed for everyone. Made for teams.',
    description:
      'No more missing fonts, broken layouts or manual versioning of presentations. For teams and individuals, collaboration has never been easier.',
  },
  {
    imageUrl:
      'https://d33wubrfki0l68.cloudfront.net/f4c1bc4fe260124a487327e818604f88c66f4fb5/8a05e/images/features/collab.png',
    title: 'Work anywhere.On all your devices.',
    description:
      'Collaborate seamlessly across devices from wherever you may be – whether you’re in the office, on the go, or making last-minute changes before your next meeting.',
  },
  {
    imageUrl:
      'https://d33wubrfki0l68.cloudfront.net/b304c53ec496028d3fc207fad9d7a750e463fa2f/a616e/images/features/templates.png',
    title: 'Powerful templates. Next-level layouts.',
    description:
      'Poorly designed presentations are a thing of the past. Create beautiful and high-quality content that is aligned with your brand, in just a few clicks.',
  },
  {
    imageUrl:
      'https://d33wubrfki0l68.cloudfront.net/f950147a990f7cfb79790bdedd330c1138982538/b52a5/images/features/integrations.png',
    title: 'Data from everywhere. Updated in real time.',
    description:
      'Manually updating your business reports and sales pitches is tedious and painful. With Pitch, connect to external data sources to turn your presentations into living documents.',
  },
];

const demoFormInitalValue = { email: '' };

export default () => (
  <Layout>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="description" content="Grab a slice of some tasty toast!" />
      <title>Tasty Toast</title>
    </Helmet>
    <Hero.Video />
    <Section>
      <Headline>The future of presenting starts here.</Headline>
      <Teaser>An open platform for presentations and content collaboration. Sign up to get early access.</Teaser>
      <Formik
        onSubmit={val => console.log('Submitting form', val)}
        initialValues={demoFormInitalValue}
        render={({ handleSubmit }) => (
          <Formik.Container>
            <Formik.FieldGroupWithButton name="email" type="email" autoComplete="email">
              <Button onClick={handleSubmit}>Sign up</Button>
            </Formik.FieldGroupWithButton>
          </Formik.Container>
        )}
      />
    </Section>
    <ContentBoxContainer>
      {mockContent.map(item => (
        <Card key={item.title} imageUrl={item.imageUrl} title={item.title} description={item.description} />
      ))}
    </ContentBoxContainer>
    <ArticlePreview date="October 9, 2018" title="Progressive raises $19M from Index, Slack & more" />
  </Layout>
);

export const allDishesQuery = graphql`
  {
    allContentfulDish {
      edges {
        node {
          id
          slug
          title
          description
          price
          image {
            title
            fixed(width: 160) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
  }
`;
